::-webkit-scrollbar {
  height: 3px;
  width: 5px;
  background-color: #111;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

::-webkit-scrollbar-thumb {
background-color: #777;
outline: 0px solid slategrey;
}

/************ Login Page ************/

.preApp{
  background-color: #0c1c2b;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  width: 100%;
  display: flex;
}

.preApp2 {
  background-image: url('./assets/Hexagon_.svg');
  background-size: 100% 100%;
  max-width: 100vw;
  /* height: 100vh; */
  margin: 0;
  padding: 2vh 7vw 0;
  /* background-position: bottom right; */
  display: grid;
  /* grid-template-rows: 1fr 5fr 5fr 8fr 4fr; */
  box-shadow: inset 0 0 0 2000px rgba(0,0,0,.2);
  background-repeat: no-repeat;
}

.preApp2 > * {
  max-width: 100vw;
}

#preAppHeader {
  /* padding-left: 20px; */
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr;
  animation: slideTop 1s forwards;
}

#headerLogo {
  display: flex;
  flex-direction: column;
  margin: auto;
  /* margin: auto; */
  /* margin-left: 0; */
  font-size: 40px;
}

#headerLogo p {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}

#headerLogo #caption {
  color: cadetblue;
  width: 100%;
  padding: 0;
  margin-top: -3px;
  font-size: .4em;
}

.logoSplash {
  color: #fff;
  font-family: 'Jockey One', sans-serif;

}

.preApp2 button {
  border-radius: .5em;
  height: 40px;
  font-size: 55%;
  background-color: #0056ac;
  color: #fff;
  min-width: 130px;
  margin: auto;
}

.preApp2 .logo {
  width: 20px;
  height: 20px;
  /* padding: 0px 0 15px 15px; */
  /* padding: 0 0 0 0; */
  margin: auto;
  margin-left: 1vw;
  animation: fading 3s ease infinite;
  /* justify-content: center; */
}

@media only screen and (max-width: 450px) {
  #headerLogo {
    font-size: 20px;
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: 1fr 2fr;
  }

  .preApp2 #preAppHeader {
    display: flex;
    flex-direction: column;
    animation: slideTop 1s forwards;
  }

  #headerLogo #caption {
    color: cadetblue;
    /* width: 100%; */
    margin: auto;
    margin-left: 0px ;
    /* font-size: .5em; */
  }
}

#preAppNav {
  display: flex;
  margin: auto;
  /* margin-right: 100px; */
  align-items: right;
  padding-bottom: 2vh;
  /* width: 100%; */
  /* max-width: 100vw; */
}

#preAppNav *{
  margin: auto;
  text-align: center;
}

#preAppNav a, #preAppNav a:active, #preAppNav a:visited, #preAppNav a:active {
  color: #ddd;
  text-decoration: none;
  font-size: 12px;
  padding: 0px 6px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  margin-right: 10px;
}

@media only screen and (max-width: 770px) {

  /* #preAppNav{
  display: grid;
  grid-template-areas: "div div"
                       "div div";  
  } */

  #preAppNav a {
    font-size: 8px;
    padding: 0px 4px;
  }
}

/* .preApp2 div {
  border: solid 1px black
} */



#landing {
  background-color: rgba(0,0,0,0.4);
  margin: auto;
  border-radius: 2em;
  /* max-width: 1200px; */
}

#landingAbout {
  color: #fff;
  width: 100%;
  max-width: 100vw;
  padding-left: 3px;
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  animation: slideLeft 1s forwards;
  /* animation-delay: 1s; */
  visibility: hidden;
}

@media only screen and (max-width: 850px) {
  #landingAbout {
    display: flex;
    flex-direction: column;
  }

  #landingAbout > * {
    padding: 3vw;
  }

  .preApp2 #landingAbout img{
    /* max-height: 500px; */
    max-width: 75vw;
  }
}

#landingAbout * {
  margin: auto;
}

#landingAbout img{
  max-height: 500px;
  max-width: 50vw;
}

#landingAbout h4{
  /* padding-right: 30px; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 35px;
  text-shadow: 3px 3px 30px #000;
  margin: auto;
  color: #eee;
  /* margin-top: 35%; */
  
}

#landingTop {
  display: flex;
  align-items: center;
  padding: 8vh 15vw 8vh 15vw;
  justify-content: space-evenly;
}

.preApp section{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0em 6vw 0em 0em;
}

#preAppDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  animation: slideRight 1s forwards;
  /* animation-delay: 1s; */
  visibility: hidden;
  max-width: 100vw;
}



#preAppDetails #left {
  display: grid;
  grid-template-areas:"div div div"
                      "div div div";
  
}



#preAppDetails #left div{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw;
  background-color: rgba(0,0,0,0.3);
  padding: 1vw 2vw 0px;
  border-radius: 9px;
  color: #eee;
  font-size: 13px;
  flex-direction: column;
}

#preAppDetails #right {
  margin: auto;
  color: #eee;
  margin: auto auto;
  text-shadow: 3px 3px 30px #000;
  padding: 3vw;
}

#preAppDetails h3 {
  color: cadetblue;
}

#preAppDetails #right h3{
  font-weight: 500;
  font-weight: 600;
  letter-spacing: 2px;
}

@media only screen and (max-width: 925px) {

  .preApp2 #preAppDetails {
    display: flex;
    flex-direction: column-reverse;
    /* background-color: black; */
  }
}

#pricing{
  color: #ddd;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideLeft 1s forwards;
  /* animation-delay: 1s; */
  visibility: hidden;
}

#plans {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  margin: 0;
}

@media only screen and (max-width: 625px) {
  #plans {
    display: flex;
    flex-direction: column;
  }
}

#pricing h3 {
  color: cadetblue;
  font-weight: 600;
  letter-spacing: 2px;
}

#pricing p {
  font-weight: 600;
  letter-spacing: 2px;
}

#pricing p span {
  color: cadetblue;
  cursor: pointer;
  
}

#pricing .singlePlan {
  width: 250px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 20px 2vw 0px;
  padding: 15px;
  padding-left: 20px;
  border: solid #555;
  border-width: .01em;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.singlePlan h5{
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  color: cadetblue;
  padding-bottom: 0;
  margin: 0;
}

.singlePlan h5 span {
  font-size: .7em;
}

.singlePlan caption{
  font-size: 10px;
  text-align: center;
  width: inherit;
  padding: 0 0 5px 0;
  margin: 0;
  color: wheat;
}

#pricing .singlePlan li {
  /* list-style: none; */
  padding: 5px;
  font-weight: 600;
}

#preAppBottom {
  color: cadetblue;
  padding: 80px 0;
  text-align: center;
   text-shadow: 1px 1px 35px #000;
}

#preAppBottom h3 {
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 35px;
  /* text-shadow: 1px 1px 1px #fff; */
  
}

/********* landing animations *********/

@keyframes slideTop {
  0% {
    transform: translateY(-500px);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
    visibility: visible;
  }
} 

@keyframes slideLeft {
  0% {
    transform: translateX(-2000px);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
    visibility: visible;
  }
} 

@keyframes slideRight {
  0% {
    transform: translateX(2000px);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
    visibility: visible;
  }
} 
/********* *******/

@media only screen and (max-width: 450px) {
  /* [class*="logoSplash"]{
    font-size: 1.4em;
    width: 100%;
    padding: 0;
    z-index: 1;
  } */
 [id*="landing"]{
    width: 94vw;
  }
}


#landingTop span {
  margin: -.6em 0 1em 0;
  padding: 0 0 1em 0;
  color: cadetblue;
  font-size: .75em;
  text-align: center;
}

.preApp label {
  color: #eee;
  padding-right: 1em;
  display: flex;
  align-items: center;
}

.preApp .logo {
  width: 4em;
  padding: 0 0 3em 0;
  margin: 0%;
  animation: fading 3s ease infinite;
  justify-content: center;
}

#landingLinks {
  display: flex;
  justify-content: space-between;
  font-size: .7em;
  color: #4467a2;
  padding: 0 3em;
  margin: -5em 0 1em 0;
}

#landingLinks > span{
  cursor: pointer;
}

#landing h4 {
  color: #eee;
  font-size: .8em;
  text-align: center;
}
.preApp button {
  border-radius: .5em;
  height: 40px;
  font-size: 55%;
  padding: 0 auto;
  background-color: #0056ac;
  color: #fff;
  min-width: 130px;
}

button img {
  height: 60%;
  margin: 0;
}

input{
  border-radius: .6em;
}

#userList button{
  border-radius: .2em;
  width: 65px;
  margin: 0em .5em .8em .5em;
  background-color: #ddd
}

#stripeTable{
  padding: 2vh 15vw 5vh;
}

#stripeTable p {
  color: #fff;
  font-size: .8em;
  text-align: center;
}

#paypalButtons {
  background-color:#07111a;
  margin: 5vh 15%;
  border-radius: 1em;
  padding: 2vh 0vh;
}

#paypalButtons section {
  display: flex;
  justify-content: center;
}

#paypalButtons #planSelector {
  text-align: center;
}

#welcome{
  font-size: .85em;
  padding: 1em 2em 0em;
  font-weight: 400;
}

.planDetails {
  background-color: rgba(0,0,0,0.3);
  color: #ddd;
  border-radius: 1em;
  margin: 0vh 10vw;
  font-size: .9em;
  font-family: 'Jockey One', sans-serif;
  font-family:Arial, Helvetica, sans-serif;
  padding: 0 0 .3em;
  width: 250px;
  border-bottom: #666;
  border-bottom-style: solid;
  border-bottom-width: .1em;
}

.planDetails h5 {
  padding-top: 1vh;
  background-color: #000;
  border-top-right-radius: .7em;
  border-top-left-radius: .7em;
  font-size: 1.3em;
  font-weight: bold;
  letter-spacing: .3em;
  border-bottom: #777;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  padding-bottom: 1vh;
}

.planDetails p {
  margin: 0 0 .4em 0;
  color: rgb(56, 223, 56);
  font-weight: bold;
  letter-spacing: .1em;
}

.planDetails ul{
  padding: 0 3em;
}

.planDetails li {
  text-align: left;
  font-size: .9em;
  line-height: 1.9em;
  letter-spacing: -.04em;
  font-weight: 800;
}

.selected {
  border-width: 1px;
  border-style: solid;
  border-color:cornflowerblue
}

#discountForm{
  color: #000;
  padding: 1.2em 2.5em 1.5em 0;
  font-size: .8em;
  display: flex;
  align-items: end;
  justify-content: end;
}

#discountForm input{
  color: #000;
  margin: 0 .3em 0 .6em;
  background-color: rgba(255, 255, 255, .9);
  height: 50%;
}

#discountForm label{
  color: #fff;
  padding: 0;
  margin: 0;
}

#discountForm button{
  background-color: rgb(63, 80, 91);
  color: #fff;
}

#discountFlag {
  margin: 0;
  color: rgb(200, 255, 0);
  text-align: end;
  font-size: .7em;
  padding: 0em 3em 1em 0em;
}

@media only screen and (max-width: 450px) {
  [id*="paypalButtons"]{
    font-size: .9em;
    margin: 2vh 30%;
  }
  [class*="planDetails"]{
    width: 200px;
  }

  [class*="navItems"]{
    font-size: .8em;
    display: grid;
    grid-template-columns: 1fr 1.9fr 2fr;
  }

  [class*="navItems"] li {
    padding: 0;
    margin: 0;
  }

  [class*="dropdown"] {
    font-size: .9em;
  }
  [class*="dropdown"] button{
    font-size: .9em;
  }
}

/************ Upgrade Page ************/
#upgradePage{
  background-color: rgba(0,0,0,0.9);
  margin: 2vh 3vw;
  height: 95%;
  border-radius: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  padding: 0 5vw;
}

#upgradePage a {
  color: cadetblue;
  text-decoration: none;
}

#upgradePage a:hover{
  color: rgb(56, 93, 94)
}




/************ App Level ************/

.App{
  background-color: #0c1c2b;
  color: #eee;
  padding: 0 0em;
  max-width: 100vw;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.mainContainer {
  width: 100%;
  /* max-width: 1250px; */
  background-color: #0c1c2b;
  padding-left: 0;
  border-left: .001em solid rgba(85, 85, 85, .3);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

/************ Header Elements ************/

header {
  background-color: #000;
  padding: 0 0 0 0;
  margin: 0 0em .8em 0;
}
header > .row {
  padding-left: 0em;
}


/************ Nav Bar ************/

.navBar {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: .4em 0em;
  margin: 0;
}

.navItems {
  display: grid;
  grid-template-columns: 1fr 1.9fr 1fr;
  list-style: none;
  margin: 0;
  margin: auto;
  padding: 0;
  width: 100%;
  max-width: 1250px;
  /* background-color: red; */
  /* justify-content: center; */
}

.navItems li {
  align-self: center;
}

.logoText {
  font-size: 1.4em;
  font-family: 'Jockey One', sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (max-width: 768px){
  [class*="logoText"]{
    font-size: 1.1em;
  }
}

.navItems img {
  height: 1em;
}

.navItems #beta {
  font-size: .5em;
  color: cadetblue;
  margin: 0 0 -1% -4%;
}

#navBarLinks{
  display: flex;
  list-style: none;
  justify-content: space-around;
}

.navLink, .navLink:link, .navLink:visited {
  text-decoration: none;
  font-size: .8em;
  display: block;
  color: #eee;
}

li > .navLink:active {
  color: #444;
  font-size: .9em;
}

.navItems li .active {
  border-radius: .6em;
  border-bottom-width: 15px;
  border-top-width: 15px;
  text-decoration: none;
  font-size: 1em;
  color: #888;
  font-size: .9em;
}

.navLink:hover {
  color: #888;
  text-decoration: none;
}

.active:hover{
  color: #ddd;
  text-decoration: none;
}

#userLabel {
  font-size: .7em;
  color: #444;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  padding-right: 25%;
}

.dropdown{
  color: #000;
  /* background-color: #000; */
  text-decoration: none;
  z-index: 999 !important;
  display: flex;
  justify-content: center;
}

.dropdown span {
  padding: 0em 1em 0em .5em;
}

.dropdown:hover, .dropdown:active, .dropdown:visited .dropdown a{
  text-decoration: none;
}

#mobileMenu{
  z-index: 99;
  position: absolute;
  visibility: hidden;
  background-color: #000;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 15px 10px 10px 10px;
  top: 0px;
}

.mobileDropdown button{
font-size: 15px;
text-align: center;
padding-left: 27px;
letter-spacing: -6px;
}

.mobileDropdown > * {
  /* background-color: #000; */
  display: flex;
  flex-direction: column;
  /* margin-right: 0; */
  width: 100px;
}

#mobileMenu .logoText {
  width: 40%;
}

#mobileMenu .dropdown{
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 750px){
  #desktopMenu {
    visibility: hidden;
  }
  #mobileMenu{
    visibility: visible;
  }
}

#demoNav{
  font-size: .6em;
  color: cadetblue;
  display: flex;
  align-items: center;
  max-height: 15px;
}

#demoNav span {
  border-width: 0;
  border-bottom: cadetblue;
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-style: solid;
}

#demoNav button {
  /* height: 5vh; */
  font-size: .8em;
  margin-left: 2vw;
}

/************ Dashboard ************/
#dashboardContainer{
  width: 90%;
  height: 95%;
  margin: auto;
  max-width: 1250px;
  /* background-color: rgba(0,0,0,0.2);
  border-radius: 1em; */
}

/* #dashboardContainer > h5{
  height: 1vh;
  background-color: red;
} */


/* #dashboardContainer + div {
  margin: 0 auto;
} */

/* #dashMain{
  height: 100%;
  align-items: center;
  margin: auto;
} */



#dashTop{
  padding: 50px 0 0px 0;
}

#dashMain #dashPnLSection{
  /* display: flex;
  flex-direction: column; */
  /* justify-content: flex-end; */
  /* margin-bottom: 0;
  padding-bottom: 0; */
  /* align-items: center; */
}

#dashUsername{
  font-size: 30px;
  letter-spacing: 5px;
  color: cadetblue;
  text-transform: uppercase;
}


#dashMonthPnL{
font-size: 35px;
text-transform: uppercase;
font-weight: 500;
color: #ddd;
text-shadow: 2px 2px cadetblue;
}

#dashTotalPnL {
  font-size: 20px;
  text-transform: uppercase;
  color: #ddd;
  font-weight: 700;
}

#dashPnL{
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-end;
  height: 100%;
}

#dashPnL p {
  padding: 0;
  margin: 0
}

#dashTop #dashPnLSection{
  /* margin-top: 4vh;
  width: inherit; */
  /* background-color: red; */
  margin-bottom: 0;
  padding: 10px 20px 5px;
  /* container-type: inline-size;
  container-name: dashPnLContainer; */
}

/* @container (max-wdith: 500px){
  #dashPnLSection #IntradayTotals {
    background-color: red;
  }
} */

#dashNoTrades{
  border-radius: 1em;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  padding-top: 10px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
}

#dashTop #dashPnLSection h5{
  text-align: left;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 8px 10px 0px 10px;
  margin: 0;
  background-color: #07111a;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

#dashTop #IntradayTotals {
  border-radius: 1em;
}


#dashTop #totalsHeader {
  /* background-color: ; */
  border-top-left-radius: 0em;
  border-top-right-radius: 0em;
  text-align: left;
}





.green{
  color: rgb(38, 132, 63);
}

.lightGreen {
  color: rgb(0, 207, 0);
}

.red{
  color: rgb(184, 51, 51);
}

.lightRed {
  color: rgb(255, 54, 54);
}


#dashBottom{

}


/* #dashTotalPnL span{
  text-shadow: 1px 1px 1px #ccc;
} */


#dashLifetime{
  /* margin: auto; */
  display: flex;
  flex-direction: column;

}


#dashBottom .chart-container {
min-height: 300px;
max-height: 400px;
width: 99%;
padding: 5px 2px;
margin: 0;

}

#dashBottom #LifetimeChart {
  margin: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

#dashBottom #viewButtonRow{
  margin-bottom: 20px;
}

#dashboardStats{
  background-color: rgba(0,0,0,0.1);
  border-radius: 1em;
  margin: 0px 10px;
  /* margin-top: -10px */
}

#dashboardStats #statsHeader{
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  padding: 6px 10px 5px 10px;
  margin: 0;
  font-size: 11px;
  font-weight: bold;
}

#dashboardStats #statArea div {
  align-items: flex-start;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#dashLatest{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#dashBottom #dashLatest #tradesListHeader {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /* background-color: blue; */
  font-size: 11px;
  padding-top: 0;
}

#dashLatest h5 {
  text-align: left;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 8px 10px 0px 10px;
  margin: 0;
  background-color: #07111a;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

/* #dashLatest #tradeEE {
  visibility: hidden;
} */

#dashLatest #tradesListInner {
  /* max-height: 50vh; */
  max-height: 420px;
}

#dashLatest .tradeAreaRow{
  grid-template-columns: 2fr .5fr 2fr 1fr 4fr 2fr 1.5fr;
}

#dashLatest #tagHead{
  visibility: hidden;
  height: 1px;
}

#dashLatest button {
  visibility: hidden;
}

@media only screen and (max-width: 768px){
  #dashLeft, #dashRight{
    max-width: 600px;
  }
}



/************ Top Page ************/

#welcomePage {
  display: flex;
  flex-direction: column;
  padding: 0em 4em;
  width: 85vw;
  height: 85vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, .85);
  border-radius: 2em;
  margin: auto;
  margin-top: 2vh;
}

#welcomePage p {
  font-size: 1.4em;
  font-weight: 600;
}

#welcomePage h4 {
  color: cadetblue;
  font-weight: 400;
}

#topPage {
  padding: .5em 0 .6rem 1rem;
  margin: auto;
  max-width: 1250px;
  width: 94%;
}

#dateSelect{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  max-height: 100px;
  font-size: .75em;
  font-weight: 600;
}

#dateSelect  > button {
  font-size: .8em;
  font-weight: 600;
  height: 90%;
}

#dateSelect > input {
  width: 95px;
}

.btn-sm{
  height: 30px;
}

#rangePicker{
  display: flex;
  justify-content: center;
}

#rangePicker button{
  color: #fff;
  margin: 5px 0 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .8em;
  height: 20px;
  font-size: .8em;
}

#chartToggle {
  color: #fff;
  font-size: .6em;
}

@media only screen and (min-width: 768px){
  [id*="topPage"] [id*="right"] {
    /* display: flex;
    flex-direction: column;
    margin: auto; */
    padding-top: 7vh;
    
  }

}


/************ Chart Items ************/

#noTrades {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 0vh;
  font-size: .8em;
  font-weight: 500;
  background-color: rgba(0,0,0,0.7);
  border-radius: 1em;
  margin-right: 1em;


}

#intradayChart, #lifetimeChart {
  min-height: 400px;
  /* min-height: 45vh; */
  /* max-height: 55vh; */
  /* height: 90%; */
  height: inherit;
  max-height: 500px;
  padding-bottom: 0;
  margin-bottom: -12px;
}

#intradayLeftTop{
  /* width: inherit; */
  display: flex;
  flex-direction: column;
  margin: auto;
}

#intradayChart {
  width: 100%;
  padding: 0;
  margin: 0;
}

.chart-container{
  padding-bottom: 1em;
  padding-right: 1em;
  margin: 0 0 -1em 0;
  max-height: 90%;
  min-height: 80%;
  align-items: center;
}

#viewButtonRow{
  display: flex;
  justify-content: right;
  margin: 0 1em 5em 0;
}

#viewButtonRow span {
  font-size: 1.5em;
}


#intradayStats{
  /* padding-right: 2vw; */
  overflow-x: hidden;
  font-weight: 500;
  border-radius: 1em;
  display: flex;
  margin: auto;
  justify-content: center;
}

#IntradayTotals{
  background-color: #1d243c;
  padding: auto;
  /* border-top-right-radius: inherit; */
  border-top-right-radius: 2em;
  /* border-top-left-radius: .8em; */
  padding: 0em 0em;
}

#totalsHeader, #statsHeader{
  background-color: #07111a;
  font-size: .7rem;
  font-weight: bold;
  padding-bottom: .3rem;
  padding-top: .5em;
  
}

#totalsHeader{
border-top-right-radius: 1em;
width: 100%
}

#totalsHeader > div {
  /* padding-left: 0.7em; */
}
.totalsEntry {
  font-size: .7em;
  display: flex;
  align-items: flex-end;
  margin: auto;
  padding: .05vh 0vw;
}

.totalsRow {
  /* width: inherit; */
  display: grid;
  margin: 0;
  grid-template-columns: 1fr 1.5fr 1.5fr 2fr;
  padding-left: 1em;
}

.totalsRow > div, #totalRow > div {
  padding-left: .5em;
}

#totalRow {
  font-weight: 700;
  border-top: .003em solid #555;
  padding-bottom: .2em;
  font-size: .7em;
  width: 100%;
  padding-left: 1em;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 5fr;
}

#totalRow > div {
  padding-bottom: .2em;
}

#feeAndCommRow {
  font-weight: 600;
  padding-top: .2em;
  padding-bottom: .7em;
  font-size: .65em;
  width: 100%;
  padding-right: 1em;
  margin: 0;
  text-align: right;
  /* padding-left: 1.5em; */
  /* letter-spacing: .01em; */
  /* color: rgb(210, 57, 57); */
  /* background-color: #000; */
}

.totalsEntry > div {
  padding-top: 2px;
  padding-bottom: 2px;
}

.smallSymb {
  font-size: .9em;
  padding-top: .1em;
  padding-left: 0em;
}

@media only screen and (max-width: 768px) {
  #topPage #intradayStats > .row {
    width: 100%;
    border-radius: 1em;
  }

  #topPage #totalsHeader{
    border-top-left-radius: 1em;
  }

  #IntradayTotals{
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;

  }

  #intradayLeftTop {
    width: 95%;
  }
}

@media only screen and (max-width: 584px) {

  [id*="totalsHeader"] div {
    font-size: .8em;
    text-align: left;
  }


  [id*="totalRow"] div {
    font-size: .9em;
    padding: 0;
  }

  .totalsRow {
    display: grid;
    margin: 0;
    grid-template-columns: 1.4fr 1.6fr 1.5fr 1.7fr;
    padding-left: .4em;

  }

  .totalsRow {

    text-align: center;
  }

  #IntradayTotals #totalRow{
    padding-left: .4em;
    grid-template-columns: 1.4fr 4.8fr;
  }

  #dashPnLSection #IntradayTotals #totalRow{
    padding-left: 1em;
  }


  #totalRow .smallSymb {
    font-size: .8em;
    display: flex;
    padding-top: 1px;
    padding-left: 2px;
  }


  [class*="totalsEntry"] div{
    font-size: .85em;
    height: 30px;
    display: flex;
    align-items: center;
  }

}

@media only screen and (max-width: 400px) {
  .totalsRow {
    font-size: .7em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0fr;
  }

  #totalsHeader {
    text-align: left;
  }

  .totalsRow .totalsGainShare{
    width: 0;
    font-size: 0px;
    background-color: red;
  }

  #IntradayTotals #totalRow{
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}
@media only screen and (max-width: 768px) {
  /* [id*="topPage"][class*="row"] [id*="IntradayTotals"] [id*="totalsHeader"]{
    background-color: red;
    color: red;
    border-radius: inherit;
  } */
 [id*="topPage"] [id*="intradayStats"]{
    /* border-radius: .8em; */
    width: 95vw;
  }
  [id*="topPage"] {
      /* border-top-right-radius: 0em; */
  }

 #topPage #intradayStats #statArea {
    border-bottom-left-radius: 0em;
  }

  #topPage #totalsHeader {
    border-top-right-radius: 0em;
  }

    #topPage #statsHeader{
    border-top-right-radius: 1em;
  }
  
}

.totalsEntry > div {
  /* padding: .13em .7em; */
}

.symbolLink, .symbolLink:visited {
  text-decoration: none;
  color: #eee;
}

.symbolLink:hover{
  text-decoration: none;
  color: #bbb
}



/************ Stat Field ************/

#intradayStats #statArea {
  background-color: rgba(0,0,0,0.1);
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.lifetimeStats {
  border-radius: 1em;
}

.lifetimeStats #statsHeader {
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
}

.lifetimeStats .statsBottom{
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.statField, #statArea {
  padding: 0 0 0 0;
  margin-bottom: 0;
}

.statsArea{
  margin: 0;
}


.statField > p, .statField > div, #statArea > div, #statArea > p {
  font-size: .75em;
  padding: .2em 0 .3em 0em;
  margin: 0 0em 0;
}

#statArea > div > div {
  padding-left: 10px;
}
 
.statField > p:nth-child(odd), .statField > div:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

.statField > p:nth-child(even), .statField > div:nth-child(even) {
  border-left: .001em solid rgba(0, 0, 0, 0.2);
}

.largestLink {
  font-size: .8em;
}

@media only screen and (max-width: 450px) {
  [id*="statArea"]{
    font-size: .72em;
  }
  .statsArea{
    font-size: .67em;
  }

  #right{
    padding: 0
  }

  [id*="statsHeader"] div {
    font-size: .7em;
  }

  [class*="statField"] div {
    margin: auto;
  }
}

/************ Bottom Page ************/

#bottomPage {
  background-color: #0c1c2b;
  border-top: .001em solid rgb(85, 85, 85, .4);
  margin: auto;
  max-width: 1250px;
  /* background-color: rgba(0,0,0,0.05); */
}

/************ Intraday List Area ************/
#tradesListHeader {
  background-color: #07111a;
  padding-left: .5rem;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: .9rem;
  font-weight: bold;
  text-align: justify;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin: auto;

  display: grid;
  grid-template-columns: 13fr 1.75fr 1.75fr;
  align-items: flex-end;

}

.intradayList{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* overflow: auto; */
  max-height: 45vh;
  font-size: 1.1em;
  padding-top: 0em;
}

.intradayList #tradesArea {
  /* max-width: 100%; */
  width: 90vw;
  margin: auto;
  margin-bottom: 10px;
  padding: 0em;
  max-width: 1050px;
}

#tradesArea {
  container-type: inline-size;
}

#tradesListInner{
  overflow: auto;
  max-height: 30vh;
  border-bottom-left-radius: .7em;
  border-bottom-right-radius: .7em;
  /* max-width: 100%; */
}

.max45{
    max-height: 45vh;
}


.entryExit {
  /* flex-direction: row; */
  display: grid;
  grid-template-columns: 4fr .5fr 4fr;
  padding: 0;
  }

.entryExit div {
  justify-content: center;
  display: flex;
  justify-self: center;
}

#tradeEE div {
  font-size: 1em;
}


.intradayListSide{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: auto;
  max-height: 90vh;
  font-size: .95em;
  min-width: 400px;
}

.tradeEntry {
  padding: 0em 0em 0em 0em;
  color: #ddd;
  margin-right: 0em;
  border-right: .001em solid rgba(85, 85, 85, .3);
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  max-width: 100%;
}

.tradeEntryHeader {
  padding: .5em 0em .8em 0em;
  color: #ddd;
  font-size: .55em;
  font-weight: bold;
  background-color:#07111ad4;
  text-align: center;
  max-width: 100%;
}

.tradeAreaRow {
  display: grid;
  grid-template-columns: 2fr .5fr 2fr 1fr 4fr 2fr 1.5fr 0fr 3.5fr;
  padding: 0px 0px 3px 0px;
  margin: 0em;
}

.fncList {
  visibility: hidden;
  overflow: hidden;
  font-size: .01em;
}

#export {
  display: flex;
  justify-content: end;
  margin: auto;
}

#export .button {
  height: 20px;
  font-size: 10px;
  margin-top: 5px;
  padding: 2px 8px 0px;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #111;
  border: 0;
}

#export .button:hover, #export .button:active, #export .button:visited {
  text-decoration: none;
  color: #ddd;
}

#export span {
  margin: auto;
}

#tradesArea #export u {
  /* display: inline-block; */
  border: 0;
  /* rotate: 90deg; */
  /* font-size: 15px; */
}


@container (max-width: 900px) {
  /* #tradesArea .fncList {
    visibility: hidden;
    overflow: hidden;
    font-size: .01em;
  } */

  #dashLatest .fncList {
    visibility: hidden;
    overflow: hidden;
    font-size: .01em;
  }
}

@media only screen and (min-width: 825px) {
  .tradeAreaRow {
    grid-template-columns: 2fr .5fr 2fr 1fr 4fr 2fr 1.5fr 1.5fr 3.5fr;
  }

  .tradeAreaRow .fncList {
    visibility:visible;
    overflow: visible;
    font-size: 1em;
  }

  .tradeEntry .fncList {
    font-size: .6em;
  }

}

.tradeAreaRow form {
  height: 8px;
  margin: 0;
  padding: 0;
}

.tradeAreaRow select{
  padding: 0;
  margin: 0;
  width: 90%;
  /* background-color: rgba(255,255,255,0.1); */
  background-color: rgba(0,0,0,0.3);
}



#tagForm{
  color: cadetblue;
  border-radius: 1.4em;
}

.tagBubble{
  /* border: solid 1px #999;
  border-radius: .8em;
  border-top-left-radius: 0;
  border-color: cadetblue; */
  width: 90%;
  /* max-height: 20px; */
  margin: auto;
  padding: 0px 3px;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
}

.tagBubble span {
  padding: 0 4px 0px 0px;
  font-size: 12px;
  margin: 0;
}

.tagBubble .symbolLink{
  color: cadetblue;
}

@media only screen and (max-width: 500px) {
.tradeEntry{
  font-size: .9em;
}

  #tradePnL{
    font-size: .6em;
  }
}

@media only screen and (max-width: 450px) {
  [class*="tradeAreaRow"]{
    font-size: 65%;
    grid-template-columns: 1.5fr .3fr 1.5fr .8fr 4.2fr 1.5fr 1.5fr 0fr 2fr;
    width: 93vw;
  }
  [class*="tradeEntryHeader"]{
    font-size: 30%;
  }

  #feeAndCommRow {
    font-size: .5em;
  }

  #tradesListHeader{
    font-size: .60em;
    /* display: grid;
    grid-template-columns: 5fr 1fr 1fr; */
  }
}

#duration {
 font-size: .6em;
}

.win {
  background-color: rgb(38, 132, 63);
  color: white;
}

.avgwin, .avgwin:visited {
  color: rgb(0, 207, 0);
  font-weight: 600;
  text-decoration: none;
}

span .avgwin:hover, .avgwin:active {
  color: rgb(0, 148, 0);
  text-decoration: none;
}

.loss {
  background-color: rgb(184, 51, 51);
  color: white;
}



.avgloss, .avgloss:visited {
  color: rgb(255, 54, 54);
  font-weight: 700;
  text-decoration: none;
}

span .avgloss:hover, .avgloss:active{
  color: rgb(159, 0, 0);
  text-decoration: none;
}


#statArea > p {
  margin: 0 0 .3em 0;
  padding: .2em .5em;
  font-size: .8em;
}

#tradeTime {
  background-color: #132a3e;
  padding: 0 1em;
  font-size: .6em;
}

#tradePnL {
  padding: 0 0em;
  font-weight: 600;
  text-align: center;
}

#tradeShares {
  padding: 0 0 0 0;
  margin-left: 0em;
}

#tradeSymbol {
  font-weight: bold;
  padding: 0 0 0 0;
  
}

#statField p {
  font-size: .7em;
  text-align: left ;
}

p {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.tradeEntry div {
  font-size: .7em;
}

/* Calendar Items */

@media only screen and (max-width: 600px){
  [id*="tradeCalendar"]{
    padding: 0em 0em 0em 0em;
    margin: 0;
  }
  [class*="calendar"]{
    width: inherit;
    margin: 0 0;
    padding: 0 0 0 0;
    font-size: .73em;
    font-weight: 700;
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar .calDay {
  height: 20vw;
  min-height: 80px;
  max-height: 110px;
  padding: .5em;
  min-width: 42px;
}

/* @media only screen and (max-width: 550px){
   .calLink .calPnL{
    font-size: .8em;
  }

   .calDay {
    height: 10px;
  }
} */

@media only screen and (max-width: 425px){
  [class*="calendar"] [class*="calDay"]{
    font-size: .8em;
    min-height: 35px;
  }
  [class*="dayShares"]{
    visibility: hidden;
  }
  [class*="calPnL"]{
    padding-top: 1em;
  }
}

.sunday {
  grid-column-start: 1;
  background-color: #00000037;
}

.monday {
  grid-column-start: 2;
}

.tuesday {
  grid-column-start: 3;
}

.wednesday {
  grid-column-start: 4;
}

.thursday {
  grid-column-start: 5;
}

.friday {
  grid-column-start: 6;
}

.saturday {
  grid-column-start: 7;
  background-color: #00000037;
}  

.day-name {
  background: #1d243c;
  color: #eeeeee;
}

#tradeCalendar {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0em 2em 2em;
  max-width: 1000px;
}

.calendarYear {
  display: grid;
  grid-template-areas: "div div div"
                        "div div div"
                        "div div div"
                        "div div div";
  column-gap: 0px;
  justify-items: center;
  justify-content: space-evenly;
  gap: 1px 1px;

}

@media only screen and (max-width: 650px){

  [class*="calendarYearly"] [class*="calendarYear"]{
    padding: 0;
    grid-template-areas: "div div"
                        "div div"
                        "div div"
                        "div div"
                        "div div"
                        "div div";
  }
  [class*="month"]{
    /* width: 35vw; */
  }

}


#calButton {
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 3em;
  margin-bottom: 0em;
  margin-top: 1em;
  z-index: 1;
}

#calButton button{
font-size: .7em;
}

#yearContainer{
  width: 100%;
  padding: 0 5vw;
}



.calendarYear .month {
  width: 150px;
}

.calendarYear .monthData{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.month p {
  margin-bottom: 0;
  margin-top: 1em;
  cursor: pointer;
}

.month p:hover{
  color: #bbb;
}
.calendarYear li {
  width: 22px;
  height: 22px;
  border-width: .01vw;
  border-radius: .2em;
  border-color: #666;
  border-style: solid;
  margin: 3px 1px;
}

@media only screen and (max-width: 650px){
  [class*="calendarYear"] li {
    width: 14px;
    height: 14px;
  }
}


ol {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.calendar li {
  border: solid 1px hsl(0, 19%, 6%);
  border-radius: 9%;
} 

.yearLink, .yearLink span{
  display: flex;
  width: inherit;
  height: inherit;
}

#monthTotals{
text-align: center;
padding: 0;
font-size: .9rem;
}

#monthTotals > p > span {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: .6em;
  padding: 0.1em 4em 0.3em 4em;
}

.calLink, .calLink:visited {
  color: #fff;
  text-decoration: none;
}

.calLink:hover{
  color: #ccc;
  text-decoration: none;
}

.calDate {
  font-size: .6em;
  padding-left: .2em;
}

.calPnL {
  padding-top: .75vh;
  font-size: .8em;
  text-align: center;
}

.calStats {
  font-size: .7em;
  line-height: .925em;
}

.calStats div {
  padding-bottom: .4em;
}

#monthLabel {
  font-size: 1.7em;
  color: #ddd;
  align-items: center;
  text-align: center;
  margin: auto .5em;
  padding-top: 5%;
}

#calNav {
  align-items: center;
  text-align: center;
  margin-bottom: .2em;
  padding-bottom: .2em;
  vertical-align:top;
}

/************ Single Stock Page ************/

#buttonBar {
  display: flex;
  margin-bottom: 10px;
}

#buttonBar #symbolsBack {
  margin: 0;
  padding: 0;
}

#journalDiv{
  margin: auto;
  padding: 0 0 0 0;
}

#journalSpace{
  background-color: rgba(0,0,0,0.5);
  padding: 0;
  border-radius: 1em;
  width: 85%;
  margin: auto;
  margin-bottom: 1vh;
  max-width: 1100px;
}
#journalEntry {

font-size: .8em;
}

#journalEntry > div > div{
  border-width: 1px;
}

#journalEntry #savedEntry {
  border-radius: .5em;
  border-width: 0px;
  border-style: solid;
  border-color: cadetblue;
  margin: 0;
  min-height: 40px;
  padding: 1vw 2vw;
}

.journalButtons{
  display: flex;
  align-items: center;
}

.journalButtons button, #tradesListHeader button{
  background-color: rgba(255,255,255,0.09);
  color: #fff;
  font-size: 19px;
  height: 2vh;
  border-radius: .2em;
  margin: .1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: cadetblue
}

.journalButtons button:disabled, #tradesListHeader button:disabled{
  background-color: rgba(255,255,255,0.02);
  color: #333;
}

#entryHeader{
  display: flex;
  padding: 0vh  1.5vw;
  height: 3vh;
  min-height: 30px;
  font-weight: bold;
  justify-content: space-between;
  background-color: rgba(0,0,0,0.4);
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  font-size: .8em;
  border-bottom: rgba(255,255,255,0.1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

#entryHeader span {
  display: flex;
  align-items: flex-end;
}

#entryHeader + div{
  background-color: rgba(0,0,0,0.2);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  box-shadow: inset 0 0 10px #000;
  color: #ddd;
  font-weight: 500;
  
}

#journalEntry .rsw-editor .rsw-toolbar{
  background-color: rgba(0,0,0,0.1);
  border: solid 1px #333;
  border-radius: 0;
}

#journalEntry .rsw-editor {
  border: solid 1px rgba(95, 158, 160, 0.451);
  border-radius: inherit;
}
.rsw-editor .rsw-btn {
  color: #ddd;
}

.rsw-ce {
  border-color: red;
}

#journalEntry .rsw-btn:hover, #journalEntry .rsw-btn:active {
  color: cadetblue;
  background-color: #000;
}

#journalEntry .rsw-btn[data-active=true] {
  background: cadetblue;
  color: #000
}

#journalEntry input {
  background-color: rgba(0,0,0,0.5);
  border-radius: inherit;
  border-width: 0;
  margin: 0;
}

#entryHeader .journalButtons {
  color: #000;
}

#singleStock {
  background-color: #0c1c2b;
  max-width: 1250px;
  margin: 0;
  margin: auto;
  padding: 2vh 2vw 0vh;
}

#singleStockTotal {
  padding: 0;
}

#singleStockTotal div{
  padding: .5em 2em;
}

#singleStatArea{
  /* border-top-right-radius: 1em;
  border-top-left-radius: 1em; */
  border-radius: 1em;
  margin: 0;
  width: 100%;
  padding: 0;
  background-color: rgba(0,0,0,0.1);
}

#singleStatArea #statsHeader {
  border-radius: inherit;
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
  width: 100%;
  margin: 0;
  padding: .5vh 0;
}

#singleStatArea #statArea {
  /* padding-left: 10px; */
}

#singleTotalHeader{
  background-color: #07111a;
  font-size: .7em;
  font-weight: bold;
  padding-bottom: .3rem;
  padding-top: .3em;
  padding-left:1em;
}

#singleStockTotal span {
  background-color: red;
}

#singleRight {
  display: grid;
  grid-template-rows: auto 5fr;
}

#singleRight > div{
  margin-bottom: .5em;
}

#singleLeft > div{
  margin-bottom: .5em;
}

.singleStockList {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: auto;
  max-height: 75vh;
  font-size: 1.1em;
  padding-top: 0em;
}

/* .singleStockList #tradesListHeader {
  border-radius: 1em;
} */

#singleStockHeader {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  /* display: grid;
  grid-template-rows: 1fr 1fr; */
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  text-align: center;
}

#singleStockHeader span {
  align-self: center;
  font-size: 1.2em;
  font-weight: bold;
}

#singleStockHeader button {
  align-self: center;
}

.chartArea h4 {
  margin: 0;
  text-align: center;
}

.black {
  background-color: black;
}

#candlestickChartError{
  text-align: center;
  color: cadetblue;
}

#candleStickChart {
  padding: 1vh 0 1vh 2vw;
  background-color: #111;
  width: 98%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 2vh; 
  margin-bottom: 1vh;
  border-radius: 1em;
}

#candleStickChart p {
  padding: 0;
  margin: 0;
  font-size: .8em;
  width: 100%;
}

#candlestickChartError {
  font-size: .8em;
  margin: 2vh 0vh 1vh 0vh;
}


/************ Stats Page ************/

#noTags{
  width: 100%;
  text-align: center;
  color: cadetblue;
}

#statsPage h4{
  text-align: center;
  font-size: 14px;
  color: #eee;
  width: 100%;
  padding: 24px 0px 0px;
  margin: 0;
  letter-spacing: 2px;
}

#statCharts{
  display: grid;
  margin: auto;
  max-width: 90%;
  grid-template-areas: 
                      "a b"
                      "c d"
                      "e f"
                      "g h";
}

@media only screen and (max-width: 664px){
  #statCharts{
    display: flex;
    flex-direction: column;
  }
}

.tagChartContainer{
  position: relative;
  min-height: 300px;
  max-height: 370px;
  padding: 3vh 1vw 6vh;
  /* width: 30%; */
  margin: auto;
  /* margin: 0; */
  /* width: inherit; */
}

.tagChartContainer h5 {
  text-align: center;
  font-size: 14px;
  background-color: #000;
  border-radius: 8px;
  padding: 2px 0px;
  width: 100%;
  /* margin: auto; */
  margin-bottom: 4px;
  color: #bbb;
}

/************* Single Lifetime Page ***********/

#symbolsBack button {
  font-size: 10px;
  padding: 3px 6px;
  text-overflow: ellipsis;
  vertical-align:text-top;
  border-radius: 3px;
  color: white;
  background-color: #007bff;
  margin-left: 3.5vw;
}

#symbolsBack .singlePage {
  margin-top: 10px;
}

#singleLifetimePage #top {
  display: flex;
  flex-direction: row;
  padding-bottom: 5vh;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 650px){
  #singleLifetimePage #top {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px;
  }
}

#singleLifetimeHeader{
  font-weight: 600;
  letter-spacing: 1px;
  padding-left: 1.8vw;
  padding-top: 15px;
  padding-bottom: 7px;
}

#singleLifetimePage > div {
  padding: 0vw 2vw;
}

#singleLifetimeList {
  padding-left: 1vw;
  /* padding-bottom: 0;
  margin-bottom: 0; */
}

#singleLifetimeList li {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  margin: 2px 0;
}

#listHeader {
  background-color: rgba(0,0,0,0.4);
  padding: 5px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
}

#singleLifetimeList div {
  padding: .3vh 0vw;
}

#symbolStats div{
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  /* font-size: 12px; */
}

#symbolStats div span:nth-child(2) {
  padding-left: 6px;

}

.invisLink {
  text-decoration: none;
}

#symbolStats{
  /* background-color: rgba(0,0,0,0.1); */
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  padding-bottom: 8px;
  margin-top: 59px;
  margin-left: 2vw;
  /* border-radius: 1em;
  margin: 0px 10px; */
  /* margin-top: -10px */
  font-size: 12px;
}

@media only screen and (max-width: 650px){
  #symbolStats {
    margin-top: 10px
  }
}

#symbolStats h5{
  background-color: rgba(0,0,0,0.4);
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  padding: 6px 8px 5px;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

#symbolStats > div {
  padding: 4px 8px;
}

#symbolStats > div:nth-child(odd), #symbolStats > div:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

#symbolStats > div:nth-child(even), #symbolStats > div:nth-child(even) {
  border-left: .001em solid rgba(0, 0, 0, 0.2);
}

.largest{
  font-size: 10px;
}

/* #dashboardStats #statArea div {
  align-items: flex-start;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
} */



/**********************************************/






/************ Upload Page ************/

#uploadPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  margin: auto;
  margin-top: 2vh;
  padding-top: 1vh;
  border-radius: 1.5em;
  min-height: 80vh;
  width: 80%;
  max-width: 1250px;

}

#uploadPage h4 {
  margin-bottom: 1.5em;
}

#uploadPage section {
  width: 80%;
  margin-top: 1em;
  margin-bottom: 1em;
}

#brokerSelect{
  display: flex;
  flex-direction: column;
}

#broker {
  padding: 0.5vh 0vw 0vh;
}

#broker select{
  color: #000;
  margin-left: 1vw;
  border-radius: .5em;
  padding: .4vh 1vw;
}

/* input[type="file"]{
  display: none;
} */

#inputTag input {
  background-color: red;
}

#dasDate input {
  color: #000;
}

#refreshButton {
  background-color: #444;
  border-radius: 5px;
  margin-bottom: 5px;
}

#guidelines {
  display: flex;
  background-color: #00000049;
  flex-direction: column;
  padding: 1em;
  font-size: .8em;
  border-radius: 1.5em;

}

#guidelines img {
  display: flex;
  margin: auto;
  padding: 2vh 0vh;
  width: 80%;
}

/************ Settings ************/

#settingsPage{
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 1250px;
  background-color: rgba(0,0,0,0.5);
  padding-top: 3vh;
  border-radius: 1.5em;
  font-size: .8em;
  margin-top: 2vh;
  margin-bottom: 3vh;
}

#settingsUserName {
  max-width: 1250px;
}

#settingsPage ol{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1vh 2vw 2vh 2vw;
}

#settingsPage li {
  padding: 1vh 0;
}

#settingsForm{
  width: 100%;
  padding: 1vh 2vw 4.2vh 2vw;
}

#settingsList button , #settingsForm button, #subDetails button, #tagSettings button{
  height: 22px;
  margin: 0;
  font-size: .9em;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1vw;
}

#settingsPage .settingsButton {
  height: 22px;
  margin: 0;
  font-size: .9em;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1vw;
}

#dataReset {
  padding-bottom: 25px;
}

#dataReset {
  text-align: center;
}

#dataReset h5 {
  color: #ddd;
  font-size: 17px;
  padding-bottom: 5px;
}

#timezoneSelect select{
  color: #000;
}

@media only screen and (max-width: 600px){
[id*="timezoneSelect"] select{
  font-size: .8em;
  display: flex;
  width: 98%;
  }
  [id*="timezoneSelect"] {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}


#subDetails {
  width: 100%;
  padding: 0;
  margin: 0;
}

#daylightToggle {
  margin: 0;
  padding: 0;
}

#daylightToggle {
  display: flex;
}

#daylightToggle input {
  margin: auto;
  padding: 0;
}

#settingsUserName {
  text-align: center;
  background-color: #0000009e;
  padding: .5em;
  width: inherit;
}

#subDetails span, #settingsList span, #settingsForm span{
  font-weight: bold;
  color: cadetblue;
}

#tagSettings{
  width: 100%;
  /* padding: 0 2vw; */
}

#tagSettings h5{
  text-align: center;
}

#tagSettings ul {
  list-style: none;
  padding: 0;
}

#tagSettings li {
  padding: 1vh 2vw 0vh 2vw;
}

#tagSettings li span {
  color: cadetblue;
  padding-right: 5px;
}

#tagSettings li input {
  padding: 0 0 0 7px;
  margin: 0;
  height: 50%;
  width: 45%;
  color: rgb(53, 91, 93);
  font-weight: 600;
}

#tagSettingsButtons {
  padding: 0px 0px 20px 2vw;
}

/******************* SYMBOL PAGE *******************/

.symbolsList {
  /* margin: auto;
  display: flex;
  flex-direction: column; */
  width: 60vw;
  min-width: 800px;
  margin: auto;
  font-size: 11px;
  /* padding-bottom: 1vh; */
}

@media only screen and (max-width: 800px) {
  .symbolsList {
    width: 99vw;
    min-width: 0;
    padding: 0 10px
  }
}
.symbolsList h4 {
  padding-left: 10px;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 5px 0px 15px;
}

#listBody{
  overflow-x: hidden;
  height: 85vh;
  margin-bottom: 2vh;
}

.symbolsList li {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  padding: .12vw 0vw;
  font-weight: 700;
}

@media only screen and (max-width: 800px) {
  .symbolsList li {
    display: grid;
    grid-template-columns: 1fr 1fr .8fr 0fr 0fr .8fr 1fr 1fr;
    padding: 1px 0;
    font-size: 9px;
    font-weight: 500;
    
  }

  .symbolsList .symbolTradeCount{
    visibility: hidden;
    width: 0px;
    overflow: hidden;
    font-size: 0px;
  }

  .symbolsList .symbolShares{
    visibility: hidden;
    width: 0px;
    overflow: hidden;
    font-size: 0px;
  }

  .symbolsList .symbolName {
    /* max-width: 30px; */
    overflow-x: hidden;
  }
}

.symbolsList #tableHeader {
  background-color: #000;
  padding: 1.5vh 0;
  border-top-left-radius: 1.2em;
  border-top-right-radius: 1.2em;
  /* font-size: .9em; */
}

#tableHeader div {
  cursor: pointer;
}

#tableHeader span {
  color: cadetblue;
  padding: 0 1px;
}

.symbolsList li div {
  padding: 0 1vw;
  margin: auto;
  width: 100%;
  text-align: center;
}

/************ Footer ************/

footer {
  color: #999;
  background-color: black;
  padding: .9em .5em 0em;
  font-size: .8em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
}

@media only screen and (max-width: 500px){
  footer{
    display: grid;
    grid-template-columns: .5fr 1.5fr;

  }

  [id*="footerCoName"]{
    font-size: .7em;
  }

  [class*="footerLinks"] span {
    padding: 0 0 0;
  }
}


#footerLinks {
  display: flex;
  justify-content: flex-end;
}

.footerLink{
  cursor: pointer;
}

#footerLinks span {
  padding: 0 2em
}

.floating{
  color: #fff;
  width: 90vw;
  position: absolute;
  top: 0%;
  left: 0%;
  height: 90vh;
  margin: 2vh 3vw;
  padding: 1vh 4vw 3vh 4vw;
  z-index: 10000;
  background-color: rgba(0, 0, 0, .85);
  overflow-y: auto;
  border-radius: 2em;
}

#footerLinks #floatingTop {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: -2.5em;
}

.floating #floatingText h4 {
  text-align: center;
  font-size: 20px;
  padding: 2vw 0;
}

#floatingText p {
  font-size: 14px;
  font-weight: 500;
}

#floatingText > section {

}

#logoHeader {
  display: flex;
 flex-direction: row ;
}

#logoHeader img{
  margin-left: 15px;
  width: 50px;
  height: 50px;
}

.popClose{
  cursor: pointer;
  font-size: 2em;
  font-weight: bold;
  padding: .05em 0 .1em;
  margin: 3vh 0 0 0;
  color: cadetblue;
  transform: scaleY(0.8);
  transform-origin: 0 0;
  border: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: .5em;
  text-align: center;
  width: 12%;
  margin-right: 0em;
}

.centered{
  text-align: center;
}

@media only screen and (max-width: 500px){
  [class*="popClose"]{
    /* margin-right: -.5em; */
  }
}


.closed{
  visibility: hidden;
}

#logo {
  font-family: 'Jockey One', sans-serif;
  font-size: 3.25em;
  padding-bottom: 5px;
}
.imgContainer{
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1.0) 20%, transparent 100%);
}
.imgContainerR{
  mask-image: radial-gradient(circle, rgba(0, 0, 0, 1.0) 0%, transparent 100%);
}
.imgContainerE{
  mask-image: radial-gradient(ellipse at top, #211a38, transparent);
  mask-image: radial-gradient(ellipse at bottom, #000000, transparent);
}
#chartImg {
  width: 35vw;
  display: flex;
  margin: auto;
  z-index: -2;
}
#chartImgStats {
  width: 20vw;
  display: flex;
  margin: auto;
  z-index: -2;
}
#chartImgCal {
  width: 20vw;
  display: flex;
  margin: auto;
  z-index: -2;
}

#chartImgCalMon {
  width: 20vw;
  display: flex;
  margin: auto;
  z-index: -3;
}

#floatingText .imgRow{
display: flex;
flex-direction: row;
margin: auto;
align-items: center;
justify-content: center;
padding: 2vw 0vw;
}

/************ Loader ************/
.loadingSpace{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #FFF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 1em;
}

.loader + p {
  font-size: .7em;
  text-align: center;
  animation: fading 3s ease infinite;
}

/************ Animations ************/

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

@keyframes fading {
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 0%;
  }
} 

/************* switch CSS ***************/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}